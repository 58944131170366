$(document).ready(function () {
  function valideForms(form) {
    // Кастомное правило для проверки казахстанского номера телефона
    jQuery.validator.addMethod("kazakhPhone", function (value, element) {
      // Функция getOperatorCode проверяет формат и возвращает true для корректных номеров
      return this.optional(element) || getOperatorCode(value) === true;
    }, "Введите корректный казахстанский номер телефона.");

    $(form).validate({
      rules: {
        Contact: {
          required: true,
          minlength: 2
        },
        MobilePhone: {
          required: true,
          minlength: 11, // Длина номера для Казахстана
          kazakhPhone: true // Кастомное правило для проверки казахстанского номера
        },
        Commentary: {
          required: false,
        },
        MscAutoBrandStr: {
          required: true,
          minlength: 2
        },
        MscAutoModelStr: {
          required: true,
          minlength: 2
        },
        MscFuelType: {
          required: true,
          minlength: 3
        },
        MscCarBody: {
          required: true,
          minlength: 4
        }
      },
      messages: {
        Contact: {
          required: 'Введите имя',
          minlength: jQuery.validator.format('Минимум {0} символа!')
        },
        MobilePhone: {
          required: 'Введите номер',
          minlength: jQuery.validator.format('Поле заполнено не до конца!'),
          kazakhPhone: 'Введите корректный казахстанский номер телефона!' // Сообщение для казахстанского номера
        },
        Commentary: {
          minlength: jQuery.validator.format('Минимум {0} символов!')
        },
        MscAutoBrandStr: {
          required: 'Введите марку',
          minlength: jQuery.validator.format('Минимум {0} символа!')
        },
        MscAutoModelStr: {
          required: 'Введите модель',
          minlength: jQuery.validator.format('Минимум {0} символа!')
        },
        MscFuelType: {
          required: 'Введите тип топлива',
          minlength: jQuery.validator.format('Минимум {0} символа!')
        },
        MscCarBody: {
          required: 'Введите тип кузова',
          minlength: jQuery.validator.format('Минимум {0} символа!')
        }
      }
    });

  }

  valideForms('#form_consultation form')
  valideForms('#form_price form')
  valideForms('#form_choice form')
  valideForms('.choice-mobile__form');

  // result.onSuccess = function (responseData) {
  //   if (responseData.resultCode === 0) {
  //     var formSendedEvent = new CustomEvent('formSendedToCRM', {
  //       detail: { message: responseData.resultMessage }
  //     });
  //     window.dataLayer.push({
  //       'event': 'crm_lead_saved',
  //       'form_name': form.querySelector('.popup__title').innerText.trim().toLowerCase(),
  //     });
  //     document.dispatchEvent(formSendedEvent);
  //   } else {
  //     var formFailedEvent = new CustomEvent('formFailedToSendToCRM', {
  //       detail: { message: responseData.resultMessage }
  //     });
  //     window.dataLayer.push({
  //       'event': 'crm_lead_unsuccess',
  //       'form_name': form.querySelector('.popup__title').innerText.trim().toLowerCase(),
  //       'error_message': responseData.resultMessage
  //     });
  //     document.dispatchEvent(formFailedEvent);
  //   }
  // };

  // result.onError = function (jqXHR, textStatus, errorThrown) {
  //   var formFailedEvent = new CustomEvent('formFailedToSendToCRM', {
  //     detail: { message: textStatus }
  //   });
  //   window.dataLayer.push({
  //     'event': 'crm_lead_unsuccess',
  //     'form_name': form.querySelector('.popup__title').innerText.trim().toLowerCase(),
  //     'error_message': textStatus
  //   });
  //   document.dispatchEvent(formFailedEvent);
  // };

  document.addEventListener('formSendedToCRM', function (e) {
    console.groupCollapsed("📡 SEND DATA TO CRM");
    console.log(e);
    console.groupEnd();
  });

  document.addEventListener('formFailedToSendToCRM', function (e) {
    console.groupCollapsed("📡 SEND DATA TO CRM");
    console.log(e);
    console.groupEnd();
  });

  document.querySelectorAll('form.popup__form').forEach(function (form) {

    $(form).submit(function (e) {
      e.preventDefault()

      if (!$(this).valid()) {
        return;
      }

      const $form_id = form.id;
      const $form_name = form.querySelector('.popup__title').innerText.trim().toLowerCase();
      form.querySelector(".popup__btn").disabled = true;

      const jqxhr = $.ajax({
        type: 'POST',
        url: 'form.php',
        data: $(this).serialize()
      })
        .done(function (data) {
          try {
            data = JSON.parse(data);
          } catch (e) {
            afterSubmit(form, this)
            sendEventDataToGA4("lead_unsuccess", {
              "form_id": $form_id,
              "form_name": $form_name,
              "error_message": "Invalid JSON",
              "fatal": true
            });
            console.error("Invalid JSON", data);
            return;
          }
          if (data.success) {
            sendEventDataToGA4("generate_lead", {
              "form_id": $form_id,
              "form_name": $form_name,
              "sales_channel": "B2C",
              "link_text": window.pupup_btn,
              "phone_number": data.MobilePhone,
            });
            if (window.fbq) {
              fbq('track', 'Lead');
            }
          } else {
            sendEventDataToGA4("lead_unsuccess", {
              "form_id": $form_id,
              "form_name": $form_name,
              "error_message": data.error_message,
              "fatal": true
            });
          }
        })
        .fail(function (jqXHR, textStatus) {
          sendEventDataToGA4("lead_unsuccess", {
            "form_id": form.id,
            "form_name": $form_name,
            "error_message": textStatus,
            "fatal": true
          });
        })
        .always(function () {
          afterSubmit(form, this)
        });

      return false
    })
  });
})

function getOperatorCode(value) {
	const operatorCode = value.replace(/\D/g, '').substr(2, 3);
	const regexPatterns = [
    /^\+7(700|701|702|705|707|708|747|750|751|760|761|762|771|775|776|777|778)\d{7}$/,
    /^8(700|701|702|705|707|708|747|750|751|760|761|762|771|775|776|777|778)\d{7}$/
  ];

  // Удаляем все символы, кроме цифр и плюса (для международного формата)
  const sanitizedValue = value.replace(/[^+\d]/g, '');
  const normalizedValue = sanitizedValue.startsWith("7") ? `+${sanitizedValue}` : sanitizedValue;

  // Проверяем соответствие казахстанскому шаблону
  for (const pattern of regexPatterns) {
    if (pattern.test(normalizedValue)) {
      return true;
    }
  }
  return false;
}


function afterSubmit($form, $this) {
  $('.popup__consultation, .popup__price, .popup__choice').fadeOut(10)
  $('.popup__thanks').fadeIn('speed')
  $($this).find('input').val('');
  $($form).trigger('reset')
  document.body.classList.remove('no-scroll')
  setTimeout(() => $('.popup__thanks').fadeOut('slow'), 2000)
  $form.querySelector(".popup__btn").disabled = false;
}

function sendEventDataToGA4(event, data = {}) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    "event": event,
    ...data
  });
}