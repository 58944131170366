import IMask from "imask";

const mask = (selector) => {
    
    const elements = document.querySelectorAll(selector);
    elements.forEach(element => {
        const maskOptions = {
            mask: [
                {
                    mask: "+7 000 000 00 00",
                },
                {
                    mask: "8 000 000 00 00",
                }
            ]
        };
        const mask = IMask(element, maskOptions);
    });
};

export default mask;